<template>
  <div>
    <b-sidebar
      v-if="showSidebar===true"
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Novo seguimento') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <validation-observer
          #default="{ }"
          ref="formSaveValidatorNovoSeguimento"
        >
          <b-form
            @submit.prevent
          >
            <vue-perfect-scrollbar
              class="b-sidebar-scrollarea-form"
              :settings="configSettingsScroll"
            >
              <b-container
                class="pt-2 pb-0 pl-2 pr-2"
                fluid
              >
                <b-row>
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Seguimento')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <quill-editor
                          v-model="sw030s05"
                          :options="{
                            modules: {
                              toolbar: '#quill-toolbar-new',
                            },
                            placeholder: '',
                            bounds: '#quill-toolbar-new',
                          }"
                          class="quil-content border-bottom-0"
                        />
                        <div
                          id="quill-toolbar-new"
                          class="quill-toolbar d-flex justify-content-end border-top-0"
                        >
                          <button
                            v-b-tooltip.hover.top="$t('Negrito')"
                            class="ql-bold"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Itálico')"
                            class="ql-italic"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Sublinhado')"
                            class="ql-underline"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Lista')"
                            class="ql-list"
                            value="ordered"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Lista numerada')"
                            class="ql-list"
                            value="bullet"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Remover indentação')"
                            class="ql-indent"
                            value="-1"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Indentar')"
                            class="ql-indent"
                            value="+1"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Inserir link')"
                            class="ql-link"
                          />
                          <button
                            v-b-tooltip.hover.top="$t('Remover formatação')"
                            class="ql-clean"
                          />
                        </div>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row
                  v-if="formVars.usersCreate.length > 0"
                >
                  <b-col md="12">
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('Responsável')"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <v-select
                          v-model="sw030s02"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="formVars.usersCreate"
                          append-to-body
                          :calculate-position="withPopper"
                          :clearable="true"
                          label="nome"
                          item-text="nome"
                          item-value="id"
                        >
                          <template #option="{ nome }">
                            {{ nome }}
                          </template>
                          <div slot="no-options">
                            {{ $t('Sem resultados') }}
                          </div>
                        </v-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
                <b-row v-if="keyRegLeadImport === ''">
                  <b-col md="12">
                    <b-form-group>
                      <b-form-checkbox
                        v-model="sw030s14"
                        @change="changeBlockRotina"
                      >
                        {{ $t('Rotina') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>

                <template
                  v-if="sw030s14===false"
                >
                  <b-row
                    v-if="formVars.tipos.length > 0"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Tipo')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="sw030s13"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="formVars.tipos"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            label="sw090s02"
                            item-text="sw090s02"
                            item-value="sw090s01"
                          >
                            <template #option="{ sw090s02 }">
                              {{ sw090s02 }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Data do evento')"
                        >
                          <date-picker
                            v-model="sw030s04"
                            value-type="format"
                            :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                            type="date"
                            :editable="false"
                            :clearable="true"
                            :lang="langCalendar"
                            :append-to-body="true"
                            prefix-class="sw"
                          >
                            <template #icon-clear>
                              <v-select-deselect />
                            </template>
                          </date-picker>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="formVars.typeSend.length > 0"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Todo o dia ou hora?')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="sw030s18"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="formVars.typeSend"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            label="txt"
                            item-text="txt"
                            item-value="id"
                            @input="chooseAllDayOrHour"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="sw030s04_time_show===true"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Hora do evento')"
                        >
                          <b-form-timepicker
                            v-model="sw030s04_time"
                            placeholder="00:00"
                            hide-header
                            now-button
                            :label-close-button="$t('Fechar')"
                            :label-now-button="$t('Hora atual')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </template>

                <template v-else>
                  <b-row
                    v-if="formVars.tipos.length > 0"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Tipo')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="rot_sw030s13"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="formVars.tipos"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            label="sw090s02"
                            item-text="sw090s02"
                            item-value="sw090s01"
                          >
                            <template #option="{ sw090s02 }">
                              {{ sw090s02 }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <b-row
                    v-if="formVars.diaDaSemana.length > 0"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Dia da semana')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="rot_sw030s15"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="formVars.diaDaSemana"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            label="txt"
                            item-text="txt"
                            item-value="id"
                            @input="changeBlocoDiaDaSemana"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Dia da semana -->
                  <template
                    v-if="showBlocoDiaDaSemana===1"
                  >
                    <b-row
                      v-if="formVars.diaSemana.length > 0"
                    >
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Dia semana')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="rot_sw030s16"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="formVars.diaSemana"
                              append-to-body
                              :calculate-position="withPopper"
                              :clearable="true"
                              label="txt"
                              item-text="txt"
                              item-value="id"
                            >
                              <template #option="{ txt }">
                                {{ txt }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </template>

                  <!-- 15 em 15 dias -->
                  <template
                    v-if="showBlocoDiaDaSemana===2"
                  >
                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Data de início')"
                          >
                            <date-picker
                              v-model="rot_sw030s04"
                              value-type="format"
                              :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                              type="date"
                              :editable="false"
                              :clearable="true"
                              :lang="langCalendar"
                              :append-to-body="true"
                              prefix-class="sw"
                            >
                              <template #icon-clear>
                                <v-select-deselect />
                              </template>
                            </date-picker>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </template>

                  <!-- 1º dia do mês -->
                  <template
                    v-if="showBlocoDiaDaSemana===3"
                  >
                    <b-row
                      v-if="formVars.diaSemana.length > 0"
                    >
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Dia semana')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="rot_sw030s16"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="formVars.diaSemana"
                              append-to-body
                              :calculate-position="withPopper"
                              :clearable="true"
                              label="txt"
                              item-text="txt"
                              item-value="id"
                            >
                              <template #option="{ txt }">
                                {{ txt }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </template>

                  <!-- De mês a mês -->
                  <template
                    v-if="showBlocoDiaDaSemana===5"
                  >
                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          :rules="{ required: true, between: { min: 1, max: 12 } }"
                        >
                          <b-form-group
                            :label="$t('Nº de meses')"
                          >
                            <b-form-input
                              v-model="rot_sw030s04_month"
                              v-mask="['##']"
                              placeholder=""
                              autocomplete="off"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                    <b-row
                      v-if="formVars.typeWeekMonth.length > 0"
                    >
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Tipo')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="rot_type_week_month"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="formVars.typeWeekMonth"
                              append-to-body
                              :calculate-position="withPopper"
                              :clearable="true"
                              label="txt"
                              item-text="txt"
                              item-value="id"
                              @input="changeBlocoTypeWeekMonth"
                            >
                              <template #option="{ txt }">
                                {{ txt }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <!-- De mês a mês :: 1º dia da semana do mês -->
                    <template
                      v-if="showBlocoTypeWeekMonth===1"
                    >
                      <b-row
                        v-if="formVars.diaSemana.length > 0"
                      >
                        <b-col md="12">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Dia semana')"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <v-select
                                v-model="rot_sw030s16"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="formVars.diaSemana"
                                append-to-body
                                :calculate-position="withPopper"
                                :clearable="true"
                                label="txt"
                                item-text="txt"
                                item-value="id"
                              >
                                <template #option="{ txt }">
                                  {{ txt }}
                                </template>
                                <div slot="no-options">
                                  {{ $t('Sem resultados') }}
                                </div>
                              </v-select>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </template>

                    <!-- De mês a mês :: Dia do mes -->
                    <template
                      v-if="showBlocoTypeWeekMonth===2"
                    >
                      <b-row>
                        <b-col md="12">
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Dia do mês')"
                            >
                              <date-picker
                                v-model="rot_sw030s04"
                                value-type="format"
                                :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                                type="date"
                                :editable="false"
                                :clearable="true"
                                :lang="langCalendar"
                                :append-to-body="true"
                                prefix-class="sw"
                              >
                                <template #icon-clear>
                                  <v-select-deselect />
                                </template>
                              </date-picker>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </template>
                  </template>

                  <!-- Campo geral -->
                  <b-row
                    v-if="formVars.typeSend.length > 0"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Todo o dia ou hora?')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="rot_sw030s18"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="formVars.typeSend"
                            append-to-body
                            :calculate-position="withPopper"
                            :clearable="true"
                            label="txt"
                            item-text="txt"
                            item-value="id"
                            @input="chooseRotinaAllDayOrHour"
                          >
                            <template #option="{ txt }">
                              {{ txt }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="rot_sw030s04_time_show===true"
                  >
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Hora do evento')"
                        >
                          <b-form-timepicker
                            v-model="rot_sw030s04_time"
                            placeholder="00:00"
                            hide-header
                            now-button
                            :label-close-button="$t('Fechar')"
                            :label-now-button="$t('Hora atual')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </template>

              </b-container>
            </vue-perfect-scrollbar>
            <div
              class="d-flex p-1 justify-content-center"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
                @click.stop.prevent="saveForm"
              >
                {{ $t('Guardar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mr-1"
                @click.stop.prevent="clearForm"
              >
                {{ $t('Limpar') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click.stop.prevent="hide"
              >
                {{ $t('Cancelar') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import store from '@/store'
import {
  BSidebar, BFormGroup, BButton, BRow, BCol, BForm, BContainer, BFormCheckbox, BFormTimepicker, BFormInput, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { onUnmounted } from '@vue/composition-api'
import modelSeguimentos from '@store-modules/crm/leads/seguimentos'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import checkNested from 'check-nested'
import { quillEditor } from '@/views/modules/components/editor-html'
import { mask } from 'vue-the-mask'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BRow,
    BCol,
    BForm,
    ValidationObserver,
    ValidationProvider,
    VuePerfectScrollbar,
    BContainer,
    vSelect,
    BFormCheckbox,
    BFormTimepicker,
    BFormInput,
    quillEditor,
    DatePicker: oDatepicker,
    vSelectDeselect: vSelect.components.Deselect,
  },
  directives: {
    Ripple,
    mask,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    keyReg: {
      type: String,
      required: false,
      default: '',
    },
    dynamicReg: {
      type: String,
      required: false,
      default: '',
    },
    typeLead: {
      type: Number,
      required: false,
      default: 0,
    },
    refreshListview: {
      type: Function,
      required: false,
      default: () => {},
    },
    keyRegLeadImport: {
      type: String,
      required: false,
      default: '',
    },
    closeModalNewSeguimento: {
      type: Function,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      showSidebar: false,
      langCalendar: {},

      sw030s05: null,
      sw030s02: null,
      sw030s14: false,

      // Evento normal
      sw030s13: null,
      sw030s04: null,
      sw030s04_time: null,
      sw030s04_time_show: false,
      sw030s18: null,

      // Evento rotina
      rot_sw030s13: null,
      rot_sw030s15: null,
      rot_sw030s04_month: null,
      rot_type_week_month: null,
      rot_sw030s16: null,
      rot_sw030s04: null,
      rot_sw030s18: null,
      rot_sw030s04_time: null,
      rot_sw030s04_time_show: false,
    }
  },
  computed:
  {
    ...mapGetters('seguimentos', ['formVars']),
    showBlocoDiaDaSemana() {
      if ((this.rot_sw030s15 !== null) && (this.rot_sw030s15 !== undefined) && ('id' in this.rot_sw030s15)) {
        return Number(this.rot_sw030s15.id)
      }

      return 0
    },
    showBlocoTypeWeekMonth() {
      if ((this.rot_type_week_month !== null) && (this.rot_type_week_month !== undefined) && ('id' in this.rot_type_week_month)) {
        return Number(this.rot_type_week_month.id)
      }

      return 0
    },
  },
  async mounted() {
    await this.$store.dispatch('seguimentos/popupAddSeguimento', {
      keyReg: this.keyReg || '',
      typeLead: this.typeLead || '',
    }).then(async () => {
      this.showSidebar = true

      await this.importLocale(this.$i18n.locale).then(res => {
        this.langCalendar = res.default
      })

      await this.fieldsDefault()
    }).catch(error => {
      this.closeModalForm()
      this.showMsgErrorRequest(error)
    })
  },
  methods: {
    closeModalForm() {
      if (typeof this.$parent.closeFormSeguimento !== 'undefined') {
        this.$parent.closeFormSeguimento()
      }

      if (typeof this.closeModalNewSeguimento !== 'undefined' && this.closeModalNewSeguimento !== null) {
        this.closeModalNewSeguimento()
      }
    },
    async saveForm() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorNovoSeguimento.validate()
          .then(async valid => {
            if (valid) {
              const formDataSeguimento = new FormData()
              formDataSeguimento.append('sw030s03', this.keyReg || '')
              formDataSeguimento.append('sw030s08', this.dynamicReg || '')
              formDataSeguimento.append('sw030s40', this.keyRegLeadImport || '')

              if (checkNested(this.sw030s02, 'id') && (this.sw030s02.id !== '')) {
                formDataSeguimento.append('sw030s02', this.sw030s02.id)
              }

              formDataSeguimento.append('sw030s05', this.sw030s05 || '')

              if (this.sw030s14 === true) {
                formDataSeguimento.append('sw030s14', 1)

                if (checkNested(this.rot_sw030s13, 'sw090s01') && (this.rot_sw030s13.sw090s01 !== '')) {
                  formDataSeguimento.append('rot_sw030s13', this.rot_sw030s13.sw090s01)
                }

                if (checkNested(this.rot_sw030s15, 'id') && (this.rot_sw030s15.id !== '')) {
                  formDataSeguimento.append('rot_sw030s15', this.rot_sw030s15.id)

                  // Dia da semana
                  if (Number(this.rot_sw030s15.id) === 1) {
                    if ((this.rot_sw030s16 !== null) && (this.rot_sw030s16 !== undefined) && ('id' in this.rot_sw030s16)) {
                      formDataSeguimento.append('rot_sw030s16', this.rot_sw030s16.id)
                    }
                  }

                  // 15 em 15 dias
                  if (Number(this.rot_sw030s15.id) === 2) {
                    formDataSeguimento.append('rot_sw030s04', this.rot_sw030s04 || '')
                  }

                  // 1º dia do mês
                  if (Number(this.rot_sw030s15.id) === 3) {
                    if ((this.rot_sw030s16 !== null) && (this.rot_sw030s16 !== undefined) && ('id' in this.rot_sw030s16)) {
                      formDataSeguimento.append('rot_sw030s16', this.rot_sw030s16.id)
                    }
                  }

                  // De mês a mês
                  if (Number(this.rot_sw030s15.id) === 5) {
                    formDataSeguimento.append('rot_sw030s04_month', this.rot_sw030s04_month || '')

                    if (checkNested(this.rot_type_week_month, 'id') && (this.rot_type_week_month.id !== '')) {
                      formDataSeguimento.append('rot_type_week_month', this.rot_type_week_month.id)

                      if (Number(this.rot_type_week_month.id) === 1) {
                        if ((this.rot_sw030s16 !== null) && (this.rot_sw030s16 !== undefined) && ('id' in this.rot_sw030s16)) {
                          formDataSeguimento.append('rot_sw030s16', this.rot_sw030s16.id)
                        }
                      }

                      if (Number(this.rot_type_week_month.id) === 2) {
                        formDataSeguimento.append('rot_sw030s04', this.rot_sw030s04 || '')
                      }
                    }
                  }
                }

                if ((this.rot_sw030s18 !== null) && (this.rot_sw030s18 !== undefined) && ('id' in this.rot_sw030s18)) {
                  formDataSeguimento.append('rot_sw030s18', this.rot_sw030s18.id)

                  if (Number(this.rot_sw030s18.id) === 0) {
                    formDataSeguimento.append('rot_sw030s04_time', this.rot_sw030s04_time || '')
                  }
                }
              } else {
                formDataSeguimento.append('sw030s14', 0)

                if (checkNested(this.sw030s13, 'sw090s01') && (this.sw030s13.sw090s01 !== '')) {
                  formDataSeguimento.append('sw030s13', this.sw030s13.sw090s01)
                }

                formDataSeguimento.append('sw030s04', this.sw030s04 || '')

                if ((this.sw030s18 !== null) && (this.sw030s18 !== undefined) && ('id' in this.sw030s18)) {
                  formDataSeguimento.append('sw030s18', this.sw030s18.id)

                  if (Number(this.sw030s18.id) === 0) {
                    formDataSeguimento.append('sw030s04_time', this.sw030s04_time || '')
                  }
                }
              }

              self.$root.$emit('app::loading', true)
              this.$store.dispatch('seguimentos/saveSeguimento', formDataSeguimento).then(res => {
                self.$root.$emit('app::loading', false)
                self.refreshListview()
                self.showMsgSuccessRequest(res)
                self.closeModalForm()
              }).catch(error => {
                self.$root.$emit('app::loading', false)
                self.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Tem de preencher os campos do seguimento')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async clearForm() {
      try {
        this.sw030s05 = null
        this.sw030s02 = null
        this.clearFieldsWithRoutine()
        this.clearFieldsWithoutRoutine()
      } catch (err) {
        this.showMsgErrorRequest({ message: this.$t('Problema ao limpar formulário') })
      }
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async fieldsDefault() {
      this.sw030s18 = this.formVars.typeSend.find(o => Number(o.id) === 1)
      this.chooseAllDayOrHour()
    },
    chooseAllDayOrHour() {
      if ((this.sw030s18 !== null) && ('id' in this.sw030s18) && (Number(this.sw030s18.id) === 0)) {
        this.sw030s04_time = null
        this.sw030s04_time_show = true
      } else {
        this.sw030s04_time = null
        this.sw030s04_time_show = false
      }
    },
    chooseRotinaAllDayOrHour() {
      if ((this.rot_sw030s18 !== null) && ('id' in this.rot_sw030s18) && (Number(this.rot_sw030s18.id) === 0)) {
        this.rot_sw030s04_time = null
        this.rot_sw030s04_time_show = true
      } else {
        this.rot_sw030s04_time = null
        this.rot_sw030s04_time_show = false
      }
    },
    changeBlockRotina() {
      if (this.sw030s14 === true) {
        this.clearFieldsWithRoutine()
      } else {
        this.clearFieldsWithoutRoutine()
      }
    },
    changeBlocoDiaDaSemana() {
      this.clearFieldsWithRoutine(true)
    },
    changeBlocoTypeWeekMonth() {
      this.rot_sw030s16 = null
      this.rot_sw030s04 = null
    },
    clearFieldsWithoutRoutine() {
      this.sw030s14 = false
      this.sw030s13 = null
      this.sw030s04 = null
      this.sw030s04_time = null
      this.sw030s04_time_show = false
      this.sw030s18 = null
      this.$refs.formSaveValidatorNovoSeguimento.reset()
    },
    clearFieldsWithRoutine(skipFieldsMain) {
      if (skipFieldsMain !== true) {
        this.rot_sw030s13 = null
        if (this.formVars.diaDaSemana.length > 0) {
          this.rot_sw030s15 = this.formVars.diaDaSemana.find(o => Number(o.id) === 1)
        }
      }
      this.rot_sw030s04_month = null
      this.rot_type_week_month = null
      this.rot_sw030s16 = null
      this.rot_sw030s04 = null
      this.rot_sw030s18 = null
      this.rot_sw030s04_time = null
      this.rot_sw030s04_time_show = false
      this.$refs.formSaveValidatorNovoSeguimento.reset()
    },
  },
  setup() {
    const MODEL_SEGUIMENTOS = 'seguimentos'

    if (!store.hasModule(MODEL_SEGUIMENTOS)) {
      store.registerModule(MODEL_SEGUIMENTOS, modelSeguimentos)

      onUnmounted(() => {
        if (store.hasModule(MODEL_SEGUIMENTOS)) store.unregisterModule(MODEL_SEGUIMENTOS)
      })
    }

    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
